import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import HoverVideoComponent from './HoverVideo';

function Hero() {
  return (

<div>
  <img src="/img/cannonkissanetext.svg" alt="Cannon Kissane Text" className='pt-8 md:pt-12 w-full'/>

  <div id='HERO2' className='grid w-full grid-cols-1 md:grid-cols-2 gap-12 py-12'>

  <div className='bg-meta w-full'>
        <div className='grid grid-cols-1 gap-3'>
          <a href="https://www.linkedin.com/in/cannonkissane/" target="_blank" rel="noopener noreferrer">
          <div className='bg-[#FF4321] lg:bg-metal border border-metal-1 rounded-lg flex justify-center items-center aspect-[16/9] hover:bg-[#FF4321]'>
                <img src="/img/cannonpng.png" alt="Cannon Kissane Picture" className='h-full lg:hover:scale-[130%] scale-[130%] lg:scale-[%100] transition-all origin-bottom'/>
          </div>
          </a>
          <div className='text-white font-medium text-xl'>Cannon Kissane</div>
          <div className='text-gray-500 text-md leading-[.5]'>Designer & Coder</div>
        </div>
      </div>

      <a href="mailto:kissane@stanford.edu" target="_blank" rel="noopener noreferrer">
      <div className='bg-meta w-full '>
        <div className='grid grid-cols-1 gap-3'>
          <div className='group text-white bg-ckyellow lg:bg-metal border border-metal-1 rounded-lg flex flex-col justify-center items-center aspect-[16/9] hover:bg-ckyellow'>
          <div className="px-2 text-2xl md:text-xl xl:text-2xl 2xl:text-3xl font-bold group-hover:bg-metal rounded-md lg:bg-none bg-metal">Hello.</div>
          <div className=" px-2 mt-4 md:mt-1 lg:mt-2 text-1xl md:text-lg xl:text-1xl 2xl:text-2xl group-hover:bg-metal rounded-md lg:bg-none bg-metal">I'm a design engineer at Stanford.</div>
          <div className=" px-2 mt-4 md:mt-1 lg:mt-2 text-1xl md:text-lg xl:text-1xl 2xl:text-2xl group-hover:bg-metal rounded-md lg:bg-none bg-metal">Let's build something together.</div>

          </div>
          <div className='text-white font-medium text-xl fontwri'>Contact Me</div>
          <div className='text-gray-500 text-md leading-[.5]'>I Don't Bite.</div>
        </div>
      </div>
      </a>

{/* <a href="https://apps.apple.com/us/app/screen-time-ai-meaning/id6476325504" target="_blank" rel="noopener noreferrer">
      <div className='bg-meta w-full'>
    
            <div className='grid grid-cols-1 gap-3'>
                  <HoverVideoComponent></HoverVideoComponent>
                  <div className='flex gap-2 items-center'>
                    <div className="w-3 h-3 bg-ckred rounded-full"></div>
                    <div className='text-white font-medium text-xl'>Building Meaning</div>
                  </div>
              <div className='text-gray-500 text-md leading-[.5]'>Current Status</div>
            </div>
      </div>
</a> */}
      
  </div>

    <div id='PORTFOLIO' className='grid w-full grid-cols-1 md:grid-cols-2 gap-12 py-12 border-t border-b border-metal-1'>
        
    <Link to="/portfolio/meaning">
      <div className='bg-meta w-full'>
        <div className='grid grid-cols-1 gap-3'>
          <div className='bg-[#007fff] lg:bg-metal border border-metal-1 rounded-lg flex justify-center items-center aspect-[16/9] hover:bg-[#007fff]'>
              <img src="/img/meaninglogo.svg" alt="Meaning Logo" className='w-64'/>
          </div>
          <div className='text-white font-medium text-xl'>Meaning</div>
          <div className='text-gray-500 text-md leading-[.5]'>Frontend & Product Design</div>
        </div>
      </div>
      </Link>
        
        <Link to="/portfolio/sendmagic">
      <div className='bg-meta w-full'>
        <div className='grid grid-cols-1 gap-3'>
          <div className='bg-[#FF4321] lg:bg-metal border border-metal-1 rounded-lg flex justify-center items-center aspect-[16/9] hover:bg-[#FF4321]'>
              <img src="/img/sendmagic.svg" alt="Send Magic Logo" className='w-64'/>
          </div>
          <div className='text-white font-medium text-xl'>SendMagic</div>
          <div className='text-gray-500 text-md leading-[.5]'>Frontend & Product Design</div>
        </div>
      </div>
      </Link>

      <Link to="/portfolio/amongus">
      <div className='bg-meta w-full'>
        <div className='grid grid-cols-1 gap-3'>
          <div className='bg-[#FED500] lg:bg-metal border border-metal-1 rounded-lg flex justify-center items-center aspect-[16/9] hover:bg-[#FED500]'>
              <img src="/img/amongus.svg" alt="Send Magic Logo" className='w-64'/>
          </div>
          <div className='text-white font-medium text-xl'>Among Us</div>
          <div className='text-gray-500 text-md leading-[.5]'>Merchandise Design</div>
        </div>
      </div>
      </Link>

      <Link to="/portfolio/hypothetic">
      <div className='bg-meta w-full'>
        <div className='grid grid-cols-1 gap-3'>
          <div className='bg-[#007fff] lg:bg-metal border border-metal-1 rounded-lg flex justify-center items-center aspect-[16/9] hover:bg-[#007fff]'>
              <img src="/img/hypothetic.svg" alt="Send Magic Logo" className='w-32'/>
          </div>
          <div className='text-white font-medium text-xl'>Hypothetic</div>
          <div className='text-gray-500 text-md leading-[.5]'>Product Design</div>
        </div>
      </div>
      </Link>

      <Link to="/portfolio/onemint">
      <div className='bg-meta w-full'>
        <div className='grid grid-cols-1 gap-3'>
          <div className='bg-[#FF4321] lg:bg-metal border border-metal-1 rounded-lg flex justify-center items-center aspect-[16/9] hover:bg-[#FF4321]'>
              <img src="/img/onemint.svg" alt="Send Magic Logo" className='w-64'/>
          </div>
          <div className='text-white font-medium text-xl'>OneMint</div>
          <div className='text-gray-500 text-md leading-[.5]'>Product Design & Media</div>
        </div>
      </div>
      </Link>
      


    </div>

</div>

  );
}

export default Hero;
